import React from "react";
import {
  SearchkitComponent,
  SearchkitComponentProps,
  SearchkitManager,
  ImmutableQuery,
} from "searchkit";

export type SearchWrapperChildrenProps = {
  searchkit: SearchkitManager;
  translate(key: any, interpolations?: any): any;
  getResults(): any;
  getHits(): any;
  getHitsCount(): any;
  hasHits(): boolean;
  hasHitsChanged(): any;
  getQuery(): ImmutableQuery;
  isInitialLoading(): boolean;
  isLoading(): boolean;
  getError(): any;
};

export type SearchWrapperChildren = (
  props: SearchWrapperChildrenProps,
) => React.ReactElement;

export type SearchWrapperProps = SearchkitComponentProps & {
  children: SearchWrapperChildren;
};

export class SearchWrapper extends SearchkitComponent<
  SearchWrapperProps,
  Readonly<{}>
> {
  render() {
    return React.createElement(this.props.children, {
      searchkit: this._searchkit,
      translate: this.translate,
      getResults: this.getResults,
      getHits: this.getHits,
      getHitsCount: this.getHitsCount,
      hasHits: this.hasHits,
      hasHitsChanged: this.hasHitsChanged,
      getQuery: this.getQuery,
      isInitialLoading: this.isInitialLoading,
      isLoading: this.isLoading,
      getError: this.getError,
    });
  }
}
