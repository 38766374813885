import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeVar, useReactiveVar } from "react-reactive-var";

const token = makeVar<string | null>(null);
let isFetchingToken = false;

export const useAuthToken = (silent: boolean | null = true) => {
  const { getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();
  const value = useReactiveVar(token);

  React.useEffect(() => {
    if (isFetchingToken) {
      return;
    }

    isFetchingToken = true;
    (silent ? getAccessTokenSilently : getAccessTokenWithPopup)()
      .then(token)
      .catch(console.error)
      .finally(() => (isFetchingToken = false));
  }, [getAccessTokenSilently, getAccessTokenWithPopup, silent]);

  return value;
};
