import React from "react";
import { useAuth0, RedirectLoginOptions } from "@auth0/auth0-react";
import { makeVar, useReactiveVar } from "react-reactive-var";

const authorizeUrl = makeVar<string | null>(null);
let isBuildingAuthorizeUrl = false;

const defaultOptions: RedirectLoginOptions = {
  redirectUri: `${window.location.origin}/search`,
};

export const useLoginUrl = (
  options: RedirectLoginOptions | null | undefined = defaultOptions,
) => {
  const { buildAuthorizeUrl } = useAuth0();

  React.useEffect(() => {
    if (isBuildingAuthorizeUrl) {
      return;
    }

    isBuildingAuthorizeUrl = true;
    buildAuthorizeUrl(options ?? undefined)
      .then(authorizeUrl)
      .catch(console.error)
      .finally(() => (isBuildingAuthorizeUrl = false));
  }, [options, buildAuthorizeUrl]);

  return useReactiveVar(authorizeUrl);
};
