import * as React from "react";
import * as PropTypes from "prop-types";
import { map } from "lodash";
import scrollama from "scrollama";

import { HitListItem } from "dkom-components/Result";

import "./TocHitsList.scss";

import {
  RenderComponentPropType,
  renderComponent,
  block,
  HitsListProps,
} from "searchkit";
import { Affix } from "antd";

function scrollIntoView(id: string) {
  const currentNode = document.getElementById(id);
  if (currentNode)
    currentNode.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
}

function clearActiveToc() {
  [].forEach.call(document.querySelectorAll(".toc-affix li a"), (node: any) => {
    node.className = "";
  });
}

function updateActiveToc(id: string) {
  const currentNode = document.querySelectorAll(
    `.toc-affix li a[href="#${id}"]`,
  )[0];
  if (currentNode) {
    clearActiveToc();
    currentNode.className = "current";
  }
}

export class TocHitsList extends React.PureComponent<HitsListProps, any> {
  scroller: any;
  static defaultProps = {
    mod: "sk-hits",
    itemComponent: HitListItem,
  };

  static propTypes = {
    mod: PropTypes.string,
    className: PropTypes.string,
    itemComponent: RenderComponentPropType,
    hits: PropTypes.any,
  };

  bindScroller() {
    if (this.scroller) {
      this.scroller.destroy();
    }
    this.scroller = scrollama();
    this.scroller
      .setup({
        step: ".subject-anchor", // required
        offset: "10px",
      })
      .onStepEnter(({ element }: { element: any }) => {
        updateActiveToc(element.id);
      });
  }

  componentDidUpdate(prevProps: HitsListProps) {
    if (this.props.hits !== prevProps.hits) {
      this.bindScroller();
    }
  }
  componentDidMount() {
    this.bindScroller();
  }

  render() {
    const { hits, mod, className, itemComponent } = this.props;
    const bemBlocks = {
      container: block(mod).el,
      item: block(`${mod}-hit`).el,
    };
    return (
      <React.Fragment>
        <div data-qa="hits" className={bemBlocks.container().mix(className)}>
          {map(hits, (result: any, index) => {
            return renderComponent(itemComponent, {
              key: result._id,
              result,
              bemBlocks,
              index,
            });
          })}
        </div>
        <Affix className="toc-affix" offsetTop={16}>
          <ul className="toc">
            {map(hits, (result: any) => {
              return (
                <li key={result._id}>
                  <a
                    href={"#" + result._id}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      scrollIntoView(result._id);
                    }}
                  >
                    {result._source.registryNum.replace("UP/II-034-02/", "")}
                  </a>
                </li>
              );
            })}
          </ul>
        </Affix>
      </React.Fragment>
    );
  }
}
