import React from "react";
import { SearchkitManager } from "searchkit";
import { makeVar, useReactiveVar } from "react-reactive-var";

import { useEnv } from "dkom-util/useEnv";
import { useAuthToken } from "dkom-web/token";
import { useConstants } from "dkom-web/controllers";

const searchkit = makeVar<SearchkitManager | null>(null);

export const useSearchkit = () => {
  const elasticUrl = useEnv("REACT_APP_ELASTIC_URL");
  const token = useAuthToken();
  const complaintIndex = useConstants()?.complaintIndex;
  const value = useReactiveVar(searchkit);

  React.useEffect(() => {
    if (!elasticUrl || !token || !complaintIndex || value) {
      return;
    }

    searchkit(
      new SearchkitManager(`${elasticUrl}/${complaintIndex}`, {
        httpHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }),
    );
  }, [elasticUrl, token, complaintIndex, value]);

  return value;
};
