import * as React from "react";
import moment from "moment";
import { SearchkitComponent } from "searchkit";

import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import DatePicker from "rc-calendar/lib/Picker";
import enUS from "rc-calendar/lib/locale/en_US";

import "./DateRangeCalendar.scss";

const format = "dddd D. MMMM YYYY";
const fullFormat = "ddd D.M.Y";

export class Picker extends SearchkitComponent<any, any> {
  render() {
    const props = this.props;
    const { showValue } = props;
    const calendar = (
      <RangeCalendar
        type={this.props.type}
        locale={enUS}
        format={format}
        onChange={props.onChange}
        disabledDate={props.disabledDate}
        showToday={true}
        showOk={false}
        showClear={false}
      />
    );

    return (
      <DatePicker
        prefixCls="sk-calendar-picker"
        open={this.props.open}
        onOpenChange={this.props.onOpenChange}
        calendar={calendar}
        value={props.value}
        dateFormat={format}
        align={{
          points: ["bl", "tl"],
        }}
      >
        {() => (
          <div className="sk-date-box">
            <div className="sk-date-box__label" style={{ flex: "1 0 80px" }}>
              {this.props.dateInputPlaceholder}:
            </div>
            <div className="sk-date-box__value" style={{ flex: "1 0 50%" }}>
              {showValue && moment(showValue).format(fullFormat)}
            </div>
          </div>
        )}
      </DatePicker>
    );
  }
}

export class DateRangeCalendar extends SearchkitComponent<any, any> {
  constructor(props: object) {
    super(props);
    this.state = {
      startOpen: false,
      endOpen: false,
    };
  }

  onStartOpenChange = (startOpen: boolean) => {
    this.setState({
      startOpen,
    });
  };

  onEndOpenChange = (endOpen: boolean) => {
    this.setState({
      endOpen,
    });
  };

  onStartChange = (value: [moment.Moment, moment.Moment]) => {
    this.setState({
      startValue: value[0],
      startOpen: false,
      endOpen: false,
    });
    this.handleChange(value);
  };

  onEndChange = (value: [moment.Moment, moment.Moment]) => {
    this.handleChange(value);
  };

  clearState = () => {
    const { onFinished } = this.props;
    this.setState({
      startValue: null,
      endValue: null,
    });
    onFinished({
      fromDate: null,
      toDate: null,
    });
  };

  // For disabling past dates
  disabledPastDate = (endValue: moment.Moment) => {
    if (endValue.diff(moment(), "days") < 0) {
      return true;
    }
    return false;
  };

  disabledStartDate = (endValue: moment.Moment) => {
    if (!endValue) {
      return false;
    }
    const startValue = this.state.startValue;
    if (!startValue) {
      return false;
    }
    return endValue.diff(startValue, "days") < 0;
  };

  handleChange = (value: [moment.Moment, moment.Moment]) => {
    const startValue = value[0];
    const endValue = value[1];
    const { onFinished } = this.props;
    const notToday =
      startValue > moment().endOf("day") ||
      startValue < moment().startOf("day");
    onFinished({
      fromDate: (notToday && startValue.startOf("day")) || startValue,
      toDate: endValue && endValue.endOf("day"),
    });
  };

  render() {
    const { fromDate, toDate, fromDateValue, toDateValue } = this.props;

    const fromLabel = "From";
    const toLabel = "To";

    return (
      <div>
        <Picker
          onOpenChange={this.onStartOpenChange}
          open={this.state.startOpen}
          type="start"
          showValue={fromDateValue}
          value={[fromDate, toDate]}
          onChange={this.onStartChange}
          dateInputPlaceholder={fromLabel}
        />
        <Picker
          onOpenChange={this.onEndOpenChange}
          open={this.state.endOpen}
          type="end"
          showValue={toDateValue}
          disabledDate={this.disabledStartDate}
          value={[fromDate, toDate]}
          onChange={this.onEndChange}
          dateInputPlaceholder={toLabel}
        />
      </div>
    );
  }
}
