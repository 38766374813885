import React from "react";
import { Routes, Route, Navigate } from "react-router";

import { Search } from "dkom-components/Search";

export function Navigation() {
  return (
    <Routes>
      <Route index element={<Navigate to="/search" />} />
      <Route path="/search" element={<Search />} />
    </Routes>
  );
}
