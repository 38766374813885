import { Hit } from "dkom-web/hit";
import { Complaint } from "dkom-web/complaint";
import { addHighlights } from "dkom-util/highlights";

export type DescriptionProps = {
  hit: Hit<Complaint>;
  highlight: any;
};

export const Description = ({ hit, highlight }: DescriptionProps) => {
  const { _source: source } = hit;

  let categoryText = "Odluka: -";
  if (source.status) {
    categoryText = "Odluka: " + source.status;
  }

  let strankaText = "Naručitelj: -";
  if (source.client) {
    strankaText = "Naručitelj: " + source.client;
  }

  let appellantText = "Žalitelj: -";
  if (source.appellant) {
    appellantText = "Žalitelj: " + source.appellant;
  }

  const totalTaxfulPriceText =
    "Vrijednost: " +
    parseInt(source.contractValue || "0").toLocaleString() +
    " kn";

  const skuText = "Vrsta ugovora: " + source.subject;

  const cSubject = "Predmet žalbe: " + source.cSubject;

  const comment = "Komentar: " + (source.comment || "");
  const description =
    "Predmet nabave: " +
    addHighlights(source.description, highlight?.description ?? []);

  return (
    <ul>
      {[
        description,
        categoryText,
        strankaText,
        appellantText,
        totalTaxfulPriceText,
        skuText,
        cSubject,
        comment,
      ]
        .filter((x) => x)
        .map((text, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: text }}></li>
        ))}
    </ul>
  );
};
