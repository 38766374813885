import * as React from "react";
import { PluginComponent } from "react-markdown-editor-lite";

export default class SavePlugin extends PluginComponent {
  static pluginName = "save";
  static align = "right";

  private handleClick = () => {
    this.editor.props.config.onSave(this.editor.getMdValue());
  };

  render() {
    return (
      <span className="button" onClick={this.handleClick}>
        Spremi
      </span>
    );
  }
}
