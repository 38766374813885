import React, { useState, useCallback, useRef } from "react";
import { Tag, Tooltip, AutoComplete } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { uniq, debounce } from "lodash";

type Props = {
  tags?: string | string[];
  onChanged: (tags: string | string[]) => void;
  dataSource: string[];
  onSearch: (val: string) => void;
  canEdit: boolean;
};

const EditableTagGroup = (props: Props) => {
  const [lastTags, setLastTags] = useState([] as string | string[] | undefined);
  const [tags, setTags] = useState([] as string[]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputEl = useRef<any>(null);
  const { dataSource, onSearch } = props;

  if (props.tags !== lastTags) {
    const normalizedTags = !props.tags
      ? []
      : props.tags instanceof Array
      ? props.tags
      : [props.tags];
    setTags(normalizedTags);
    setLastTags(props.tags);
  }

  const onChanged = props.onChanged;
  const submitTags = useCallback(
    (tags: string[]) => {
      setTags(tags);
      onChanged(tags);
    },
    [onChanged, setTags],
  );

  const handleClose = useCallback(
    (removedTag: string) => {
      submitTags(tags.filter((tag) => tag !== removedTag));
    },
    [tags, submitTags],
  );

  const showInput = useCallback(() => {
    setInputVisible(true);
    setTimeout(() => {
      if (inputEl && inputEl.current) inputEl.current.focus();
    }, 50);
  }, [setInputVisible, inputEl]);

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
    },
    [setInputValue],
  );

  const handleInputConfirm = useCallback(
    (selValue: string) => {
      if (selValue) submitTags(uniq([...tags, selValue]));
      setInputValue("");
      setInputVisible(false);
    },
    [tags, submitTags],
  );

  const handleBlur = useCallback(() => {
    if (inputValue) submitTags(uniq([...tags, inputValue]));
    setInputValue("");
    setInputVisible(false);
  }, [inputValue, tags, submitTags]);

  const filterOption = useCallback(
    (inputValue: any, option: any) =>
      typeof option.props.children === "string" &&
      option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !==
        -1,
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchDb = useCallback(debounce(onSearch, 200), [onSearch]);

  return (
    <div>
      {tags
        .filter((tag) => tag !== null)
        .map((tag) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable={props.canEdit}
              onClose={() => handleClose(tag)}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      {inputVisible && (
        <AutoComplete
          size="small"
          ref={inputEl}
          value={inputValue}
          style={{ width: "120px" }}
          onSelect={handleInputConfirm}
          onSearch={onSearchDb}
          onChange={handleInputChange}
          placeholder="upišite"
          onBlur={handleBlur}
          filterOption={filterOption}
        >
          {dataSource
            .filter((value) => value !== null)
            .map((value) => (
              <AutoComplete.Option key={value} value={value}>
                {value}
              </AutoComplete.Option>
            ))}
        </AutoComplete>
      )}
      {!inputVisible && props.canEdit && (
        <Tag
          onClick={showInput}
          style={{ background: "#fff", borderStyle: "dashed", width: "120px" }}
        >
          <PlusOutlined /> Novi tag
        </Tag>
      )}
    </div>
  );
};

export default EditableTagGroup;
