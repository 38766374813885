import React from "react";
import { Card } from "antd";

export type ItemContainerProps = {
  bemBlocks: any;
};

export const ItemContainer = ({
  bemBlocks,
  children,
}: React.PropsWithChildren<ItemContainerProps>) => {
  return (
    <div
      className={bemBlocks.item().mix(bemBlocks.container("item"))}
      data-qa="hit"
    >
      <Card>{children}</Card>
    </div>
  );
};
