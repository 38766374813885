import React, { useState, useCallback } from "react";
import { EditFilled, FormOutlined } from "@ant-design/icons";
import { Card, Button } from "antd";
import Markdown from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import underline from "markdown-it-plugin-underline";
import save from "dkom-components/Markdown/SavePlugin";
import cancel from "dkom-components/Markdown/CancelPlugin";
import mode from "dkom-components/Markdown/ModeToggle";
import "react-markdown-editor-lite/lib/index.css";
import { addHighlights } from "dkom-util/highlights";
import "./NoteCmp.scss";

MdEditor.use(save);
MdEditor.use(cancel);
MdEditor.use(mode);

const md = new Markdown({ breaks: true }).use(underline);

type Props = {
  text: string;
  onNoteSaved: (txt: string) => void;
  canEdit: boolean;
  className?: string;
  actions?: any[];
  highlights?: string[];
};

export function NoteCmp({
  onNoteSaved,
  text,
  canEdit,
  className,
  actions,
  highlights,
}: Props) {
  const [editNote, setEditNote] = useState(false);
  const [editVal, setEditVal] = useState(text);

  const setEdit = useCallback(() => {
    if (canEdit) setEditNote(!editNote);
  }, [editNote, canEdit]);
  const onEditChanged = useCallback((props: { text: string }) => {
    setEditVal(props.text);
  }, []);

  const getEditButton = (content: any) =>
    canEdit ? (
      <Button className="edit-btn" type="link" onClick={setEdit}>
        {content}
      </Button>
    ) : null;

  return editNote ? (
    <MdEditor
      value={editVal}
      plugins={[
        "fonts",
        "link",
        "table",
        "logger",
        "split-toggle",
        "save",
        "cancel",
      ]}
      renderHTML={(txt) => md.render(txt)}
      onChange={onEditChanged}
      // NOTE: https://github.com/HarryChen0506/react-markdown-editor-lite/blob/master/docs/configure.md
      config={{
        view: { menu: true, md: true, html: false },
        onSave: (txt: string) => {
          onNoteSaved(txt);
          setEditNote(false);
        },
        onCancel: () => {
          setEditNote(false);
          setEditVal(text);
        },
      }}
    />
  ) : editVal.trim() ? (
    <Card className={className + " note-preview"}>
      <div className="note-actions">
        {" "}
        {/* TODO: check translate */}
        {getEditButton(<EditFilled /* translate="uredi" */ />)}
        {actions}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: addHighlights(md.render(editVal), highlights),
        }}
      />
    </Card>
  ) : (
    <>
      {/* TODO: check translate */}
      {getEditButton(<FormOutlined /* translate="dodaj" */ />)}
    </>
  );
}
