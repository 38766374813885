import React from "react";
import moment from "moment";

import { ComplaintTags } from "dkom-components/ComplaintTags";
import { SearchWrapper } from "dkom-components/Search";
import { AnnotationCmp } from "dkom-components/AnnotationCmp";

import { Hit } from "dkom-web/hit";
import { Complaint } from "dkom-web/complaint";
import {
  useUpdateComplaintTags,
  useUpdateComplaintReasoning,
  useParseComplaintDecision,
  ReasoningParagraph,
  useUserCanEdit,
  useFetchComplaint,
} from "dkom-web/controllers";

import { addHighlights, ViewModes } from "dkom-util/highlights";

import { ItemContainer } from "./ItemContainer";
import { Description } from "./Description";
import { Button, Spin } from "antd";

export type HitListItemProps = {
  result: Hit<Complaint>;
  bemBlocks: {
    container: (yes: string) => string;
    item: (yes: string) => string;
  };
  index: number;
  viewMode?: ViewModes;
};

export const HitListItem = ({
  result: { highlight, ...hit },
  viewMode,
  bemBlocks,
  ...wrapperProps
}: HitListItemProps) => {
  const canEdit = useUserCanEdit();
  const parseComplaintDecision = useParseComplaintDecision();
  const updateComplaintReasoning = useUpdateComplaintReasoning();
  const updateComplaintTags = useUpdateComplaintTags();
  const fetchComplaint = useFetchComplaint();
  const [isWorking, setIsWorking] = React.useState(false);

  return (
    <SearchWrapper props={wrapperProps}>
      {({ searchkit }) => {
        const { _source: source, _id: id } = hit;

        return (
          <Spin spinning={isWorking}>
            <ItemContainer bemBlocks={bemBlocks}>
              <div className={bemBlocks.item("details")}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    title={id}
                    className={bemBlocks.item("title") + " subject-anchor"}
                    id={id}
                    dangerouslySetInnerHTML={{
                      __html: source.registryNum || "",
                    }}
                  ></h2>
                  <Button
                    size="small"
                    onClick={() => {
                      setIsWorking(true);
                      fetchComplaint(id)
                        .then(() => {
                          setIsWorking(false);
                          searchkit.reloadSearch();
                        })
                        .catch(console.error);
                    }}
                  >
                    {!isWorking ? "Sinkroniziraj" : "Radim..."}
                  </Button>
                </div>
                <ComplaintTags
                  tags={source.tags}
                  onChanged={(tags) => updateComplaintTags(id, tags)}
                />
                <h5 className={bemBlocks.item("subtitle")}>
                  Zaprimljeno:{" "}
                  {moment(source.receptionDate).format("DD. MM. YYYY.")}
                  {source.decisionDate &&
                    !source.decisionDate.startsWith("1980") &&
                    " , Odlučeno: " +
                      moment(source.decisionDate).format("DD. MM. YYYY.")}
                </h5>
                <div className={bemBlocks.item("text")}>
                  <Description hit={hit} highlight={highlight} />
                </div>
                <AnnotationCmp
                  title={"Odluka " + ((source.otherDecisions?.length ?? 0) + 1)}
                  subtitle={addHighlights(
                    source.dkomDecision?.solution,
                    highlight?.["dkomDecision.solution"] || [],
                  )}
                  text={source.dkomDecision?.reasoning}
                  highlights={highlight?.["dkomDecision.reasoning"] || []}
                  notes={source.dkomDecision?.notes || source.notes || []}
                  noteHls={
                    highlight?.["dkomDecision.notes"] || highlight?.notes || []
                  }
                  viewMode={viewMode}
                  fileName={source.dkomDecision?.fileName || ""}
                  parseFunc={() => parseComplaintDecision(id, "dkom", 0)}
                  onUpdated={() => searchkit.reloadSearch()}
                  canEdit={canEdit}
                  saveFunc={(paraphs: ReasoningParagraph[]) =>
                    updateComplaintReasoning(id, "dkom", 0, paraphs)
                  }
                />
                {source.otherDecisions?.map((x, i) => {
                  return (
                    <AnnotationCmp
                      key={"other" + i}
                      title={
                        "Odluka " + ((source.otherDecisions?.length ?? 0) - i)
                      }
                      subtitle={x.solution}
                      text={x.reasoning}
                      notes={x.notes || []}
                      viewMode={viewMode}
                      fileName={x.fileName || ""}
                      parseFunc={() =>
                        parseComplaintDecision(id, "dkom", i + 1)
                      }
                      onUpdated={() => searchkit.reloadSearch()}
                      canEdit={canEdit}
                      saveFunc={(paraphs: ReasoningParagraph[]) =>
                        updateComplaintReasoning(id, "dkom", i + 1, paraphs)
                      }
                    />
                  );
                })}
                {source.courtDecisions?.map((x, i) => {
                  const fname =
                    x.fileName.startsWith("usdokument") ||
                    x.fileName.startsWith("dokument") ||
                    x.fileName.startsWith("http")
                      ? x.fileName
                      : "usdokumenti/" + x.fileName;
                  return (
                    <AnnotationCmp
                      key={"court" + i}
                      title={"Presuda " + (i + 1)}
                      subtitle={x.judgement}
                      text={x.reasoning}
                      highlights={[]}
                      notes={x.notes || []}
                      viewMode={viewMode}
                      fileName={fname}
                      parseFunc={() => parseComplaintDecision(id, "court", i)}
                      onUpdated={() => searchkit.reloadSearch()}
                      canEdit={canEdit}
                      saveFunc={(paraphs: ReasoningParagraph[]) =>
                        updateComplaintReasoning(id, "court", i, paraphs)
                      }
                    />
                  );
                })}
              </div>
            </ItemContainer>
          </Spin>
        );
      }}
    </SearchWrapper>
  );
};
