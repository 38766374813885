import * as React from "react";
import { PluginComponent } from "react-markdown-editor-lite";
import getDecorated from "./decorate";
import Editor from "react-markdown-editor-lite";

function insertMarkdownOverride(this: Editor, type: string, option: any = {}) {
  const selection = this.getSelection();
  const config = (this as any).config;
  let decorateOption = option ? { ...option } : {};
  if (type === "image") {
    decorateOption = {
      ...decorateOption,
      target: option.target || selection.text || "",
      imageUrl: option.imageUrl || config.imageUrl,
    };
  }
  if (type === "link") {
    decorateOption = {
      ...decorateOption,
      linkUrl: config.linkUrl,
    };
  }
  const trimmed = selection.text.trimEnd();
  const trim = selection.text.replace(trimmed, "");
  const decorate = getDecorated(trimmed, type, decorateOption);
  decorate.text = decorate.text + trim;
  this.insertText(decorate.text, true, decorate.selection);
}

export default class CancelPlugin extends PluginComponent {
  static pluginName = "cancel";
  static align = "right";

  private handleClick = () => {
    this.editor.props.config.onCancel();
  };

  componentDidMount() {
    if ((this.editor as any).insertMarkdownOverriden) return;
    this.editor.insertMarkdown = insertMarkdownOverride.bind(this.editor);
    (this.editor as any).insertMarkdownOverriden = true;
  }

  render() {
    return (
      <span className="button" onClick={this.handleClick}>
        Otkaži
      </span>
    );
  }
}
