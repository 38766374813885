import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";

import { Navigation } from "dkom-components/Navigation";

import { useEnv } from "dkom-util/useEnv";

import "antd/dist/antd.min.css";
import "./App.scss";

// TODO: add favicon
export function App() {
  const auth0Domain = useEnv("REACT_APP_AUTH0_DOMAIN");
  const auth0ClientId = useEnv("REACT_APP_AUTH0_CLIENT_ID");
  const auth0Audience = useEnv("REACT_APP_AUTH0_AUDIENCE");

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={auth0Audience}
      redirectUri={window.location.origin}
      cacheLocation={"localstorage"}
      useRefreshTokens
    >
      <Router>
        <Navigation />
      </Router>
    </Auth0Provider>
  );
}
