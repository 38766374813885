import React from "react";

import { Complaint } from "dkom-web/complaint";
import { usePostData, buildUrl, Error } from "dkom-web/data";

export const useFetchComplaint = () => {
  const postData = usePostData();

  return React.useCallback(
    async (id: string, type?: "scrape" | "database") => {
      return await postData<Complaint | Error>(
        buildUrl(`/api/complaint/${id}/fetch`, {
          type,
        }),
      );
    },
    [postData],
  );
};

export const useParseComplaintDecision = () => {
  const postData = usePostData();

  return React.useCallback(
    async (id: string, type?: "dkom" | "court", decisionIndex?: number) => {
      return await postData<Complaint | Error>(
        buildUrl(`/api/complaint/${id}/parse`, {
          type,
          decisionIndex,
        }),
      );
    },
    [postData],
  );
};

export const useUpdateComplaintTags = () => {
  const postData = usePostData();

  return React.useCallback(
    async (id: string, tags: string | string[]) => {
      if (!(tags instanceof Array)) {
        tags = [tags];
      }

      return await postData<Complaint | Error>(
        buildUrl(`/api/complaint/${id}/tags`),
        tags,
      );
    },
    [postData],
  );
};

export type ReasoningParagraph = {
  text: string;
  note: string;
  noteHls: string[];
  textHls: string[];
};

export const useUpdateComplaintReasoning = () => {
  const postData = usePostData();

  return React.useCallback(
    async (
      id: string,
      type: "dkom" | "court",
      decisionIndex: number,
      paragraphs: ReasoningParagraph[],
    ) => {
      return await postData<Complaint | Error>(
        buildUrl(`/api/complaint/${id}/reasoning/${type}/${decisionIndex}`),
        paragraphs.map((x) => ({ text: x.text, note: x.note })),
      );
    },
    [postData],
  );
};
