export function throwWhenNull<T>(
  obj: T | null | undefined,
  error: string = "",
): T {
  if (!obj) {
    throw new Error(error);
  }

  return obj;
}
