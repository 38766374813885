import React from "react";
import { makeVar, useReactiveVar } from "react-reactive-var";

import { useGetData, buildUrl } from "dkom-web/data";

const userCanEdit = makeVar<boolean | null>(null);
let isFetchingUserCanEdit = false;

export const useUserCanEdit = () => {
  const getData = useGetData();

  React.useEffect(() => {
    if (isFetchingUserCanEdit) {
      return;
    }

    isFetchingUserCanEdit = true;
    getData<{ canEdit: boolean }>(buildUrl(`/api/user/canEdit`))
      .then((response) => userCanEdit(response?.canEdit))
      .catch(console.error)
      .finally(() => (isFetchingUserCanEdit = false));
  }, [getData]);

  return !!useReactiveVar(userCanEdit);
};

const userCanRead = makeVar<boolean | null>(null);
let isFetchingUserCanRead = false;

export const useUserCanRead = () => {
  const getData = useGetData();

  React.useEffect(() => {
    if (isFetchingUserCanRead) {
      return;
    }

    isFetchingUserCanRead = true;
    getData<{ canRead: boolean }>(buildUrl(`/api/user/canRead`))
      .then((response) => userCanEdit(response?.canRead))
      .catch(console.error)
      .finally(() => (isFetchingUserCanRead = false));
  }, [getData]);

  return !!useReactiveVar(userCanRead);
};
