import React from "react";
import { uniq } from "lodash";

import EditableTagGroup from "dkom-components/EditableTagGroup";

import { useUserCanEdit, useTags } from "dkom-web/controllers";
import { isError } from "dkom-web/data";

export type Props = {
  tags?: string | string[];
  onChanged: (tags: string | string[]) => void;
};

export const ComplaintTags = ({ tags, onChanged }: Props) => {
  const [dataSource, setDataSource] = React.useState([] as string[]);
  const canEdit = useUserCanEdit();
  const [search, setSearch] = React.useState<string | null>(null);
  const searchTags = useTags();

  React.useEffect(() => {
    if (!search) {
      return;
    }

    const updateDataSource = async () => {
      const tags = await searchTags(search);
      if (!tags) {
        return;
      }

      if (isError(tags)) {
        console.error(tags);
        return;
      }

      setDataSource(search ? uniq([search, ...tags]) : tags);
    };

    updateDataSource().catch(console.error);
  }, [search, setDataSource, searchTags]);

  return (
    <EditableTagGroup
      tags={tags}
      onChanged={onChanged}
      dataSource={dataSource}
      onSearch={setSearch}
      canEdit={canEdit}
    />
  );
};
