import * as React from "react";
import moment from "moment";
import * as PropTypes from "prop-types";

import {
  SearchkitComponent,
  SearchkitComponentProps,
  RenderComponentType,
  RenderComponentPropType,
  renderComponent,
  FieldOptions,
  Panel,
} from "searchkit";

import { DateRangeAccessor } from "./DateRangeAccessor";

import { defaults, get } from "lodash";

// For testing without a calendar component. Accepts date math.
export class DateRangeFilterInput extends SearchkitComponent<any, any> {
  // NOTE: it worked like this before, so I guess it's fine
  // @ts-ignore
  refs: {
    [key: string]: any;
    dateFromInput: any;
    dateToInput: any;
  };

  handleDateFinished = (_: any) => {
    const { onFinished } = this.props;
    const newState = {
      fromDate: this.refs.dateFromInput.value,
      toDate: this.refs.dateToInput.value,
    };
    onFinished(newState);
  };

  render() {
    const { fromDate, toDate } = this.props;

    return (
      <div>
        <input id="date-from" ref="dateFromInput" defaultValue={fromDate} />
        <input id="date-to" ref="dateToInput" defaultValue={toDate} />
        <button id="date-submit" onClick={this.handleDateFinished}>
          OK
        </button>
      </div>
    );
  }
}

export interface DateRangeFilterProps extends SearchkitComponentProps {
  fromDateField: string;
  toDateField: string;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  id: string;
  title: string;
  interval?: number;
  containerComponent?: RenderComponentType<any>;
  calendarComponent?: RenderComponentType<any>;
  rangeFormatter?: (count: number) => number | string;
  fieldOptions?: FieldOptions;
}

export class DateRangeFilter extends SearchkitComponent<
  DateRangeFilterProps,
  any
> {
  // NOTE: it worked like this before, so I guess it's fine
  // @ts-ignore
  accessor: DateRangeAccessor;

  static propTypes = defaults(
    {
      fromDate: PropTypes.object,
      toDate: PropTypes.object,
      fromDateField: PropTypes.string.isRequired,
      toDateField: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      containerComponent: RenderComponentPropType,
      calendarComponent: RenderComponentPropType,
      rangeFormatter: PropTypes.func,
      fieldOptions: PropTypes.shape({
        type: PropTypes.oneOf(["embedded", "nested", "children"]).isRequired,
        options: PropTypes.object,
      }),
    },
    SearchkitComponent.propTypes,
  );

  static defaultProps = {
    containerComponent: Panel,
    rangeFormatter: (v: string) => moment(parseInt("" + v)).format("D.M.YYYY"),
  };

  defineAccessor() {
    const {
      id,
      title,
      fromDate,
      toDate,
      fromDateField,
      toDateField,
      fieldOptions,
      rangeFormatter,
    } = this.props;

    return new DateRangeAccessor(id, {
      id,
      fromDate,
      toDate,
      fromDateField,
      toDateField,
      title,
      fieldOptions,
      rangeFormatter,
      onClearState: this.handleClearState,
    });
  }

  handleClearState = () => {
    this.accessor.resetState();
  };

  defineBEMBlocks() {
    let block = this.props.mod || "sk-date-range-filter";
    return {
      container: block,
      labels: block + "-value-labels",
    };
  }

  setCalendarState = (newValues: any) => {
    if (!newValues.fromDate) {
      this.accessor.resetState();
    } else {
      this.accessor.state = this.accessor.state.setValue(newValues);
    }
  };

  calendarUpdate = (newValues: any) => {
    this.setCalendarState(newValues);
  };

  calendarUpdateAndSearch = (newValues: any) => {
    this.calendarUpdate(newValues);
    this.searchkit.performSearch();
  };

  getCalendarComponent() {
    const { calendarComponent } = this.props;
    return calendarComponent || DateRangeFilterInput;
  }

  render() {
    if (!this.accessor) return null;
    const { id, title, containerComponent } = this.props;

    return renderComponent(
      containerComponent,
      {
        title,
        className: id ? `filter--${id}` : undefined,
        disabled: this.accessor.isDisabled(),
      },
      this.renderCalendarComponent(this.getCalendarComponent()),
    );
  }

  renderCalendarComponent(component: RenderComponentType<any>) {
    const { fromDate, toDate, rangeFormatter } = this.props;
    const state: {
      fromDate?: string;
      toDate?: string;
    } = this.accessor.state.getValue();

    return renderComponent(component, {
      fromDate: state.fromDate || fromDate,
      toDate: state.toDate || toDate,
      fromDateValue: get(state, "fromDate", fromDate),
      toDateValue: get(state, "toDate", toDate),
      items: this.accessor.getBuckets(),
      onChange: this.calendarUpdate,
      onFinished: this.calendarUpdateAndSearch,
      rangeFormatter,
    });
  }
}
