import * as React from "react";
import {
  SearchBox,
  HitsStats,
  RefinementListFilter,
  Pagination,
  ResetFilters,
  SelectedFilters,
  SortingSelector,
  NoHits,
  InitialLoader,
  ViewSwitcherToggle,
  ViewSwitcherHits,
  ActionBar,
  ActionBarRow,
  InputFilter,
  NumericRefinementListFilter,
  CheckboxFilter,
  ExistsQuery,
  NestedQuery,
  BoolShould,
} from "searchkit";
import { Affix, Layout, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

import { HitGridItem, HitListItem } from "dkom-components/Result";
import {
  DateRangeFilter,
  DateRangeCalendar,
} from "dkom-components/DateRangeFilter";
import { TocHitsList } from "dkom-components/TocHitsList";
import { FacetFilterOp } from "dkom-components/FacetFilterOp";
import { LoginTimeout } from "dkom-components/Search/LoginTimeout";

import classQueryString from "dkom-util/classQueryString";

import { SearchProvider } from "./SearchProvider";
import "./Search.scss";
import logo from "logo.png";

export const Search = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  return !isAuthenticated ? (
    <Layout>
      <Layout.Header>
        <div className="search-logo">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <div className="auth">
          <Button
            className="login"
            onClick={() =>
              loginWithRedirect({
                redirectUri: `${window.location.origin}/search`,
              })
            }
          >
            Prijava
          </Button>
        </div>
      </Layout.Header>
      <Layout>
        <Layout.Content>
          <LoginTimeout />
        </Layout.Content>
      </Layout>
    </Layout>
  ) : (
    <SearchProvider>
      <Layout>
        <Layout.Header>
          <div className="search-logo">
            <img className="logo" src={logo} alt="Logo" />
            <SearchBox
              translations={{ "searchbox.placeholder": "pretraži..." }}
              queryOptions={{ minimum_should_match: "50%" }}
              autofocus={true}
              searchOnChange={true}
              queryFields={[
                "status.keyword",
                "client",
                "description",
                "dkomDecision.reasoning",
              ]}
              prefixQueryFields={["status", "client", "description"]}
            />
          </div>
          <div className="auth">
            <Button
              onClick={() =>
                logout({ returnTo: `${window.location.origin}/search` })
              }
            >
              Odjava
            </Button>
          </div>
        </Layout.Header>
        <Layout>
          <Affix>
            <Layout.Sider width={300}>
              <InputFilter
                id="registryNum_q"
                title="Klasa"
                placeholder="klasa, zadnji dio klase ili br."
                searchOnChange={true}
                queryFields={["registryNum"]}
                queryOptions={{ default_operator: "AND" }}
                queryBuilder={classQueryString}
              />
              <InputFilter
                id="reasoning_q"
                title="Obrazloženje"
                placeholder="Pretraži obrazloženja"
                searchOnChange={true}
                queryFields={["dkomDecision.reasoning"]}
                queryOptions={{ default_operator: "AND" }}
              />
              <InputFilter
                id="solution_q"
                title="Izreka"
                placeholder="Pretraži tekst odluke"
                searchOnChange={true}
                prefixQueryFields={["dkomDecision.solution"]}
                queryFields={["dkomDecision.solution"]}
              />
              <InputFilter
                id="client_q"
                title="Naručitelj"
                placeholder="Pretraži naručitelja"
                searchOnChange={true}
                prefixQueryFields={["client"]}
                queryFields={["client"]}
                queryOptions={{ default_operator: "AND" }}
              />
              <InputFilter
                id="appellant_q"
                title="Žalitelj"
                placeholder="Pretraži žalitelja"
                searchOnChange={true}
                prefixQueryFields={["appellant"]}
                queryFields={["appellant"]}
                queryOptions={{ default_operator: "AND" }}
              />
              <InputFilter
                id="description_q"
                title="Predmet nabave"
                placeholder="Pretraži predmet nabave"
                searchOnChange={true}
                prefixQueryFields={["description"]}
                queryFields={["description"]}
                queryOptions={{ default_operator: "AND" }}
              />
              <InputFilter
                id="notes_q"
                title="Bilješke"
                placeholder="Pretraži bilješke"
                searchOnChange={true}
                prefixQueryFields={["notes", "dkomDecision.notes"]}
                queryFields={["notes", "dkomDecision.notes"]}
                queryOptions={{ default_operator: "AND" }}
              />
              <CheckboxFilter
                title={""}
                id="notes_only"
                label="samo sa bilješkama"
                filter={BoolShould([
                  ExistsQuery("notes"),
                  ExistsQuery("dkomDecision.notes"),
                ])}
              />
              <CheckboxFilter
                title={"Presude"}
                id="courtDecisions_only"
                label="samo sa presudom"
                filter={NestedQuery(
                  "courtDecisions",
                  ExistsQuery("courtDecisions"),
                )}
              />
              <FacetFilterOp
                translations={{
                  "facets.view_more": "Pokaži još",
                  "facets.view_less": "Pokaži manje",
                  "facets.view_all": "Pokaži sve",
                }}
                id="tags"
                title={"Tagovi"}
                field="tags"
                size={10}
              />
              <NumericRefinementListFilter
                id="contractValue"
                title="Vrijednost nabave"
                field="contractValue"
                options={[
                  { title: "Sve" },
                  { title: "do 1 mil. kn", from: 0, to: 999999 },
                  {
                    title: "1 mil. do 10 mil. kn",
                    from: 1000000,
                    to: 9999999,
                  },
                  {
                    title: "10 mil. do 100 mil. kn",
                    from: 10000000,
                    to: 99999999,
                  },
                  { title: "više od 100 mil. kn", from: 100000000 },
                ]}
              />
              <RefinementListFilter
                translations={{
                  "facets.view_more": "Pokaži još",
                  "facets.view_less": "Pokaži manje",
                  "facets.view_all": "Pokaži sve",
                }}
                id="cSubject"
                title="Predmet žalbe"
                field="cSubject"
                operator="OR"
                size={10}
              />
              <RefinementListFilter
                translations={{
                  "facets.view_more": "Pokaži još",
                  "facets.view_less": "Pokaži manje",
                  "facets.view_all": "Pokaži sve",
                }}
                id="subject"
                title="Vrsta ugovora"
                field="subject"
                operator="OR"
                size={10}
              />
              <RefinementListFilter
                translations={{
                  "facets.view_more": "Pokaži još",
                  "facets.view_less": "Pokaži manje",
                  "facets.view_all": "Pokaži sve",
                }}
                id="status"
                title="Odluka"
                field="status.keyword"
                operator="OR"
                size={10}
              />
              <DateRangeFilter
                key="decisionDate"
                id="decisionDate"
                title="Datum odluke"
                fromDateField="decisionDate"
                toDateField="decisionDate"
                calendarComponent={DateRangeCalendar}
                fieldOptions={{
                  type: "embedded",
                }}
              />
              <DateRangeFilter
                key="receptionDate"
                id="receptionDate"
                title="Datum zaprimanja"
                fromDateField="receptionDate"
                toDateField="receptionDate"
                calendarComponent={DateRangeCalendar}
                fieldOptions={{
                  type: "embedded",
                }}
              />
            </Layout.Sider>
          </Affix>
          <Layout.Content>
            <div className="results">
              <ActionBar>
                <ActionBarRow>
                  <HitsStats
                    translations={{
                      "hitstats.results_found": "{hitCount} predmeta nađeno",
                    }}
                  />
                  <ViewSwitcherToggle />
                  <SortingSelector
                    options={[
                      {
                        label: "Datum zaprimanja desc",
                        field: "receptionDate",
                        order: "desc",
                        defaultOption: true,
                      },
                      {
                        label: "Datum zaprimanja asc",
                        field: "receptionDate",
                        order: "asc",
                        defaultOption: true,
                      },
                      {
                        label: "Vrijednost desc",
                        field: "contractValue",
                        order: "desc",
                      },
                      {
                        label: "Vrijednost asc",
                        field: "contractValue",
                      },
                    ]}
                  />
                </ActionBarRow>
                <ActionBarRow>
                  <SelectedFilters />
                  <ResetFilters
                    translations={{ "reset.clear_all": "Ukloni sve filtere" }}
                  />
                </ActionBarRow>
              </ActionBar>
              <Pagination showNumbers={true} />
              <ViewSwitcherHits
                hitsPerPage={12}
                highlightFields={[
                  "notes",
                  "dkomDecision.reasoning",
                  "dkomDecision.solution",
                  "dkomDecision.notes",
                  // NOTE: https://stackoverflow.com/a/15571068
                  // "otherDecisions.reasoning",
                  // "otherDecisions.solution",
                  // "otherDecisions.notes",
                  // "courtDecisions.reasoning",
                  // "courtDecisions.solution",
                  // "courtDecisions.notes",
                ]}
                sourceFilter={[
                  "status",
                  "client",
                  "appellant",
                  "receptionDate",
                  "description",
                  "contractValue",
                  "currency",
                  "total_quantity",
                  "subject",
                  "cSubject",
                  "decisionDate",
                  "comment",
                  "registryNum",
                  "dkomDecision.fileName",
                  "dkomDecision.reasoning",
                  "dkomDecision.solution",
                  "dkomDecision.notes",
                  "dkomDecision.comments",
                  "otherDecisions",
                  "courtDecisions",
                  "tags",
                  "notes",
                ]}
                hitComponents={[
                  {
                    key: "list",
                    title: "Normalan prikaz",
                    listComponent: TocHitsList,
                    itemComponent: HitListItem,
                    defaultOption: true,
                  },
                  {
                    key: "list2",
                    title: "Istakni bilješke",
                    listComponent: TocHitsList,
                    itemComponent: HitGridItem,
                  },
                ]}
                scrollTo="body"
              />
              <Pagination
                showNumbers={true}
                // NOTE: this doesn't work
                // NOTE: https://searchkit.github.io/searchkit/v0.10.0/docs/core/Translate.html
                translations={{
                  "pagination.next": "Sljedeća",
                  "pagination.previous": "Prošla",
                }}
              />
              <NoHits suggestionsField={"description"} />
              <InitialLoader />
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </SearchProvider>
  );
};
