import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import { Button } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

import AnnParagraphCmp from "dkom-components/AnnParagraphCmp";

import { ReasoningParagraph } from "dkom-web/controllers";

import { getAnnParagraphs } from "dkom-util/annotation";
import { hasHighlights, ViewModes } from "dkom-util/highlights";

function getMergeButton(
  canEdit: boolean,
  paraphs: ReasoningParagraph[],
  ndx: number,
  mergeFun: (ndx: number) => void,
) {
  // TODO: better key
  return canEdit && ndx < paraphs.length - 1 && !paraphs[ndx].note ? (
    <Button
      className="merge-btn"
      type="link"
      key={ndx}
      onClick={() => mergeFun(ndx)}
    >
      {/* TODO: check translate */}
      <VerticalAlignBottomOutlined /* translate="uredi" */ />
    </Button>
  ) : null;
}

type Props = {
  title: string;
  subtitle: string;
  text?: string;
  highlights?: string[];
  notes: string[];
  noteHls?: string[];
  viewMode?: ViewModes;
  fileName: string | null;
  parseFunc?: () => Promise<any>;
  onUpdated?: () => void;
  canEdit: boolean;
  saveFunc?: (paraphs: ReasoningParagraph[]) => Promise<any>;
};

export const AnnotationCmp = ({
  title,
  text,
  highlights,
  notes,
  noteHls,
  viewMode,
  fileName,
  parseFunc,
  subtitle,
  onUpdated,
  canEdit,
  saveFunc,
}: Props) => {
  const [expandAll, setExpandAll] = useState(false);
  const paraphs = useMemo(
    () => getAnnParagraphs(text || "", notes, highlights || [], noteHls || []),
    [text, notes, highlights, noteHls],
  );
  const initState = paraphs.map(
    (x) => hasHighlights(x.text, x.textHls) || hasHighlights(x.note, x.noteHls),
  );
  if (!initState.find((x) => x) && initState.length > 0 && viewMode !== "notes")
    initState[0] = true;
  const [expStates, setExpStates] = useState(initState);
  const [isWorking, setIsWorking] = useState(false);

  const parseDoc = useCallback(() => {
    setIsWorking(true);
    if (parseFunc)
      parseFunc().then(() => {
        // eslint-disable-next-line
        if (onUpdated)
          setTimeout(() => {
            setIsWorking(false);
            onUpdated();
          }, 1500);
      });
  }, [setIsWorking, parseFunc, onUpdated]);

  const onParaphSaved = useCallback(() => {
    setIsWorking(true);
    if (!saveFunc) return;
    saveFunc(paraphs).then((_: any) => {
      setIsWorking(false);
      // NOTE: onUpdated refreshes everything which is not needed here
    });
  }, [saveFunc, setIsWorking, paraphs]);

  const onMergeParaphs = useCallback(
    (part: number) => {
      paraphs[part].text += " " + paraphs[part + 1].text;
      paraphs[part].note += " " + paraphs[part + 1].note;
      paraphs.splice(part + 1, 1);
      onParaphSaved();
    },
    [onParaphSaved, paraphs],
  );
  const annotationUrl = (fileName || "").startsWith("http")
    ? fileName || ""
    : "http://pdf.dkom.hr/" + fileName;
  return fileName ? (
    <React.Fragment>
      <h4>
        {title} &nbsp;
        {text && (
          <React.Fragment>
            <Button size="small" onClick={() => setExpandAll(!expandAll)}>
              {!expandAll ? "Prikaži sve" : "Skupi sve"}
            </Button>
            &nbsp;
          </React.Fragment>
        )}
        {parseFunc && (
          <React.Fragment>
            <Button size="small" onClick={parseDoc}>
              {!isWorking ? "Skeniraj" : "Radim..."}
            </Button>
            &nbsp;
          </React.Fragment>
        )}
        <Button size="small" href={annotationUrl} target={"_blank"}>
          Otvori Pdf
        </Button>
      </h4>
      {viewMode !== "notes" && <h5>{subtitle}</h5>}
      {paraphs.map((part, i) => {
        let actions = canEdit
          ? [getMergeButton(canEdit, paraphs, i, onMergeParaphs)]
          : [];
        return (
          <React.Fragment key={i + "l" + paraphs.length}>
            <AnnParagraphCmp
              paragraph={part}
              expStates={expStates}
              expandAll={expandAll}
              expIndex={i}
              onExpStatesChanged={setExpStates}
              onParagraphSaved={onParaphSaved}
              viewMode={viewMode}
              canEdit={canEdit}
              loading={isWorking}
              actions={actions}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  ) : (
    <div></div>
  );
};
