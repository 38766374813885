import { assign } from "lodash";

export interface SimpleQueryStringOptions {
  analyzer?: string;
  fields?: Array<string>;
  default_operator?: string;
  flags?: string;
  [str: string]: any;
}

export default function classQueryString(
  query: string,
  options: SimpleQueryStringOptions = {},
) {
  if (!query) {
    return;
  }
  query = query.replace(/\/0(\d)$/, (_, p1) => "/" + p1);

  if (query.match(/^\d\d\/\d+$/) && !query.match(/^0[1,2]\/\d+$/))
    query = query.replace("/", "-01/");
  if (
    !query.startsWith("UP") &&
    (query.indexOf("/") > -1 || query.indexOf("-") > -1)
  )
    query = "*" + query;
  if (query.startsWith("UP") || query.startsWith("*"))
    query = '"' + query + '"';
  return {
    simple_query_string: assign({ query }, options),
  };
}
