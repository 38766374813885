import { ReasoningParagraph } from "dkom-web/controllers";

export function splitParaph(text: string) {
  return text.replaceAll("\r", "").split("\n\n");
}

export function getAnnParagraphs(
  text: string,
  notes: string[],
  textHls: string[],
  noteHls: string[],
): ReasoningParagraph[] {
  return splitParaph(text).map((x, i) => ({
    text: x,
    note: notes?.[i] ?? "",
    textHls: textHls ?? [],
    noteHls: noteHls ?? [],
  }));
}
