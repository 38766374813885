import * as React from "react";
import { Typography } from "antd";

import { useLoginUrl } from "dkom-web/login";

export type LoginTimeoutProps = {
  timeout?: number;
};

export const LoginTimeout = ({
  timeout = 1,
  children,
}: React.PropsWithChildren<LoginTimeoutProps>) => {
  const loginUrl = useLoginUrl();

  const [timeTillLogin, setTimeTillLogin] = React.useState<number>(timeout);

  React.useEffect(() => {
    if (!loginUrl) {
      return;
    }

    const timeoutId = setTimeout(() => {
      if (timeTillLogin > 0) {
        setTimeTillLogin(timeTillLogin - 1);
      }

      if (timeTillLogin === 0) {
        window.location.assign(loginUrl);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeTillLogin, loginUrl]);

  return (
    <div className="login-timeout">
      {timeTillLogin !== timeout && (
        <Typography.Paragraph>
          Prijavljujem Vas{" "}
          {timeTillLogin === 1
            ? "za 1 sekundu"
            : timeTillLogin === 0
            ? "sada"
            : timeTillLogin < 5
            ? `za ${timeTillLogin} sekunde`
            : `za ${timeTillLogin} sekundi`}
          ...
        </Typography.Paragraph>
      )}
      {children}
    </div>
  );
};
