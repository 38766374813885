import React from "react";
import { makeVar, useReactiveVar } from "react-reactive-var";

import { useGetData, buildUrl, Error } from "dkom-web/data";

export type Constants = { complaintIndex: string };

const constants = makeVar<Constants | null>(null);
let isFetchingConstants = false;

export const useConstants = () => {
  const getData = useGetData();

  React.useEffect(() => {
    if (isFetchingConstants) {
      return;
    }

    isFetchingConstants = true;
    getData<Constants>(buildUrl(`/api/elastic/constants`))
      .then(constants)
      .catch(console.error)
      .finally(() => (isFetchingConstants = false));
  }, [getData]);

  return useReactiveVar(constants);
};

export const useTags = () => {
  const getData = useGetData();

  return React.useCallback(
    async (prefix?: string) => {
      return await getData<string[] | Error>(
        buildUrl(`/api/elastic/tags`, {
          prefix,
        }),
      );
    },
    [getData],
  );
};
