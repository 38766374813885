const rgx = new RegExp("<em>", "g");
const rgxend = new RegExp("</em>", "g");

export function addHighlights(
  sourceTxt?: string | null,
  highlightArray?: string[] | null,
) {
  if (!sourceTxt || !highlightArray || highlightArray.length === 0)
    return sourceTxt || "";
  for (const hl of highlightArray) {
    const strippedHl = hl.replace(rgx, "").replace(rgxend, "");
    sourceTxt = sourceTxt.replace(strippedHl, hl);
  }
  return sourceTxt;
}

export function hasHighlights(
  sourceTxt?: string | null,
  highlightArray?: string[] | null,
) {
  if (!sourceTxt || !highlightArray || highlightArray.length === 0)
    return false;
  for (const hl of highlightArray) {
    const strippedHl = hl.replace(rgx, "").replace(rgxend, "");
    if (sourceTxt.indexOf(strippedHl) > -1) return true;
  }
  return false;
}

export type ViewModes = "default" | "notes" | "nonotes";
