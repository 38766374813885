import React, { useCallback } from "react";
import { Spin } from "antd";

import { ReasoningParagraph } from "dkom-web/controllers";

import { ViewModes } from "dkom-util/highlights";

import { NoteCmp } from "dkom-components/Markdown/NoteCmp";
import { Expandable, PartState } from "dkom-components/Expandable";

function getExpState(
  expStates: boolean[],
  index: number,
  noteShow: boolean,
): PartState {
  var xst = expStates[index];
  if (xst) return "expanded";
  var prev = index === 0 ? false : expStates[index - 1];
  var next = index === expStates.length - 1 ? false : expStates[index + 1];
  if (!prev && !next && !noteShow) return "hidden";
  return "collapsed";
}

type Props = {
  paragraph: ReasoningParagraph;
  viewMode?: ViewModes;
  expStates: boolean[];
  expIndex: number;
  onExpStatesChanged: (state: boolean[]) => void;
  onParagraphSaved: () => void;
  expandAll: boolean;
  canEdit: boolean;
  loading: boolean;
  actions?: any[];
};

const AnnParagraphCmp = ({
  paragraph,
  viewMode,
  onParagraphSaved,
  expandAll,
  expIndex,
  expStates,
  onExpStatesChanged,
  canEdit,
  loading,
  actions,
}: Props) => {
  let expanded = expStates[expIndex];
  const noteShown = !!(
    paragraph.text &&
    viewMode !== "nonotes" &&
    (expanded || expandAll || (viewMode === "notes" && paragraph.note))
  );

  let onBtnClick = useCallback(() => {
    expStates[expIndex] = !expStates[expIndex];
    onExpStatesChanged([...expStates]);
  }, [expStates, expIndex, onExpStatesChanged]);
  let expState = getExpState(expStates, expIndex, noteShown);
  return expState === "hidden" && !expandAll ? (
    <span>.</span>
  ) : (
    <Spin spinning={loading}>
      <Expandable
        text={paragraph.text}
        expandAll={expandAll}
        expState={expState}
        onBtnClick={onBtnClick}
      >
        <NoteCmp
          text={paragraph.text}
          onNoteSaved={(txt) => {
            paragraph.text = txt;
            onParagraphSaved();
          }}
          className="paragraph-preview"
          canEdit={canEdit}
          actions={actions}
          highlights={paragraph.textHls}
        />
      </Expandable>
      {noteShown && (
        <NoteCmp
          text={paragraph.note}
          onNoteSaved={(txt) => {
            paragraph.note = txt;
            onParagraphSaved();
          }}
          canEdit={canEdit}
          highlights={paragraph.noteHls}
        />
      )}
    </Spin>
  );
};

export default AnnParagraphCmp;
