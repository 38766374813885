import React from "react";
import { FacetFilterProps } from "searchkit/lib/components/search/filters/facet-filter/FacetFilterProps";
import { FacetFilter, SearchkitComponent } from "searchkit";
import { Switch } from "antd";
import { identity } from "lodash";

type Props = FacetFilterProps & { defaultOp?: "OR" | "AND" };

export class FacetFilterOp extends SearchkitComponent<Props, any> {
  state = {
    tagOp: "AND",
    bucketsTransform: identity,
    inputVal: "",
    size: 200,
  };

  constructor(props: Props) {
    super(props);
    if (props.defaultOp) this.state.tagOp = props.defaultOp;
  }

  tagOpChanged = () => {
    this.setState({ tagOp: this.state.tagOp === "OR" ? "AND" : "OR" });
    setTimeout(() => this.searchkit?.reloadSearch(), 200);
  };

  inputValChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    var val = e.target.value;
    this.setState({
      inputVal: e.target.value,
      bucketsTransform: !val
        ? identity
        : (items: any[]) => {
            if (!items) return items;
            return items.filter(
              (x) =>
                ((x.key as string) || "")
                  .toLowerCase()
                  .indexOf(val.toLowerCase()) > -1,
            );
          },
    });
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <div className="sk-panel__header">{this.props.title}</div>
        <input
          placeholder="filtriraj..."
          value={this.state.inputVal}
          onChange={this.inputValChanged}
        />
        <Switch
          checkedChildren="ILI"
          unCheckedChildren="I"
          checked={this.state.tagOp === "OR"}
          onChange={this.tagOpChanged}
          style={{ position: "absolute", right: "0" }}
          size="small"
        />
        <div style={{ maxHeight: "300px", overflow: "auto" }}>
          <FacetFilter
            {...this.props}
            title={""}
            bucketsTransform={this.state.bucketsTransform}
            operator={this.state.tagOp}
            size={this.state.size}
          />
        </div>
      </div>
    );
  }
}
