import { throwWhenNull } from "./throwWhenNull";

export const useEnv = (key: string) => {
  const value = throwWhenNull(
    process.env[key],
    `Update your '.env' with '${key}'`,
  );

  return value;
};
