import React from "react";
import { useAuthToken } from "dkom-web/token";

export const buildUrl = (
  base: string,
  params?: {
    [key: string]: any;
  },
) => {
  const url = new URL(base, window.location.origin);
  params &&
    Object.entries(params).forEach(([key, value]) => {
      value && url.searchParams.append(key, value.toString());
    });

  return url.href;
};

export const usePostData = (url = "", data = emptyData) => {
  return useFetchData(url, data, "POST");
};

export const useGetData = (url = "") => {
  return useFetchData(url, emptyData, "GET");
};

export type Error = {
  error: string;
  debug?: string;
};

export const isError = (obj: any): obj is Error => !!obj.error;

// NOTE: Default options are marked with *
const useFetchData = (
  initialUrl: string | null = null,
  initialData: object | null = null,
  initialMethod: string | null = null,
) => {
  const token = useAuthToken();

  return React.useCallback(
    async <T = any>(
      url: string | null = null,
      data: object | null = null,
      method: string | null = null,
    ) => {
      if (!token) {
        return null;
      }

      method = method || initialMethod || "GET";
      const response = await fetch(url || initialUrl || "", {
        // *GET, POST, PUT, DELETE, etc.
        method: method,
        // no-cors, *cors, same-origin
        mode: "cors",
        // *default, no-cache, reload, force-cache, only-if-cached
        cache: "no-cache",
        // include, *same-origin, omit
        credentials: "same-origin",
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // manual, *follow, error
        redirect: "follow",
        // no-referrer, *client
        referrerPolicy: "no-referrer",
        // *null
        body:
          method === "GET" ? null : JSON.stringify(data || initialData || {}),
      });

      const json = await response.json();
      return json as T;
    },
    [initialUrl, initialData, initialMethod, token],
  );
};

// NOTE: prevents infinite renders when no data is passed
const emptyData = {};
