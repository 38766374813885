import * as React from "react";
import Icon from "@ant-design/icons";
import { PluginComponent } from "react-markdown-editor-lite";

interface ModeToggleState {
  view: {
    html: boolean;
    md: boolean;
  };
}

enum NEXT_ACTION {
  SHOW_ALL,
  SHOW_MD,
}

export default class ModeToggle extends PluginComponent<ModeToggleState> {
  static pluginName = "split-toggle";
  static align = "right";

  private get isDisplay() {
    if (this.editorConfig.canView) {
      return this.editorConfig.canView.html && this.editorConfig.canView.md;
    }
    return false;
  }

  /**
   * 显示标准：
   * 两个都显示的时候，点击显示MD，隐藏HTML
   * 只显示HTML的时候，点击全部显示
   * 只显示MD的时候，点击显示HTML，隐藏MD
   */
  private get next(): NEXT_ACTION {
    const { view } = this.state;
    if (view.html) {
      return NEXT_ACTION.SHOW_MD;
    } else {
      return NEXT_ACTION.SHOW_ALL;
    }
  }

  constructor(props: any) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      view: this.editor.getView(),
    };
  }

  private handleClick() {
    switch (this.next) {
      case NEXT_ACTION.SHOW_ALL:
        this.editor.setView({
          html: true,
          md: true,
        });
        break;
      case NEXT_ACTION.SHOW_MD:
        this.editor.setView({
          html: false,
          md: true,
        });
        break;
    }
  }

  private handleChange(view: { html: boolean; md: boolean }) {
    this.setState({ view });
  }

  componentDidMount() {
    this.editor.on("viewchange", this.handleChange);
  }

  componentWillUnmount() {
    this.editor.off("viewchange", this.handleChange);
  }

  getDisplayInfo() {
    const next = this.next;
    switch (next) {
      case NEXT_ACTION.SHOW_ALL:
        return {
          icon: "view-split",
          title: "Pretpregled",
        };
      default:
        return {
          icon: "keyboard",
          title: "Editor",
        };
    }
  }

  render() {
    if (this.isDisplay) {
      const display = this.getDisplayInfo();
      return (
        <span
          className="button button-type-mode"
          title={display.title}
          onClick={this.handleClick}
        >
          <Icon type={display.icon} />
        </span>
      );
    } else {
      return null;
    }
  }
}
