import React from "react";
import { Layout } from "antd";
import { SearchkitProvider } from "searchkit";

import { useSearchkit } from "dkom-web/searchkit";

import logo from "logo.png";

export type SearchProviderProps = {};

export const SearchProvider = ({
  children,
}: React.PropsWithChildren<SearchProviderProps>) => {
  const searchkit = useSearchkit();

  return searchkit ? (
    <SearchkitProvider searchkit={searchkit}>
      {children as any}
    </SearchkitProvider>
  ) : (
    <Layout>
      <Layout.Header>
        <div className="search-logo">
          <img className="logo" src={logo} alt="Logo" />
        </div>
      </Layout.Header>
      <Layout>
        <Layout.Content>
          <div className="loading">Učitavanje...</div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
