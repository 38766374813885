import React from "react";
import { ColumnHeightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import "./Expandable.scss";

export type PartState = "collapsed" | "hidden" | "expanded";

export type ExpandableProps = {
  text: string;
  expandAll?: boolean;
  expState?: PartState;
  initExpState?: PartState;
  onBtnClick?: () => void;
};

export const Expandable: React.FC<React.PropsWithChildren<ExpandableProps>> = ({
  text,
  expandAll,
  expState,
  onBtnClick,
  children,
  initExpState,
}) => {
  var [inState, setInState] = useState<PartState | undefined>(initExpState);
  expState = expState || inState || "expanded";
  onBtnClick =
    onBtnClick ||
    (() => setInState(inState === "expanded" ? "collapsed" : "expanded"));
  return expState === "expanded" || expandAll ? (
    <React.Fragment>
      {children || (
        <p>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </p>
      )}
    </React.Fragment>
  ) : expState === "collapsed" ? (
    <p className="expandable-collapsed" onClick={onBtnClick}>
      {text.substring(0, 35) + " ..."}{" "}
      <Button type="link">
        {/* TODO: fix translate */}
        <ColumnHeightOutlined /* translate="" */ />
      </Button>
    </p>
  ) : (
    <span>.</span>
  );
};
